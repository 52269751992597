<template>
  <img
    v-if="!cameraAccessible"
    class="status"
    alt="Disconnected from camera"
    src="./assets/disconnected.png"
  >
  <img
    v-else
    class="status"
    alt="Connected to camera"
    src="./assets/connected.png"
  >
  <h1>Diconnected from Camerix</h1>

  <p>To connect to your camera:</p>
  <ol>
    <li>Ensure the camera is turned on</li>
    <li>Select it in the Wifi settings: Camerix-XXX</li>
    <li>Refresh this page</li>
  </ol>

  <div class="hello">
    Connection details:
    <pre>{{ connInfo }}</pre>
    Predicates
    <pre>{{ { maybeIms } }}</pre>
  </div>
</template>

<script>
import { reactive, toRefs, ref, watchEffect, computed, onUnmounted } from 'vue';

const getConnectionState = () => {
  console.log('connection', navigator.connection);
  const c = reactive({
    connInfo: {},
    maybeIms: computed(() => {
      const t = c.connInfo.type;
      return t !== 'cellular' && t !== 'none';
    }),
  });

  if (navigator.connection) {
    const getConnectionInfo = () => {
      console.log(' ', navigator.connection);
      return {
        type: navigator.connection.type,
        effectiveType: navigator.connection.effectiveType,
        downlink: navigator.connection.downlink,
        downlinkMax: navigator.connection.downlinkMax,
        rtt: navigator.connection.rtt,
        saveData: navigator.connection.saveData,
      };
    };

    c.connInfo = ref(getConnectionInfo());
    navigator.connection.onchange = () => {
      c.connInfo = getConnectionInfo();
    };
    onUnmounted(() => {
      navigator.connection.onchange = null;
    });
  }

  return { ...toRefs(c) };
};

let tryId = 0;

function tryToConnect(cb) {
  let timeoutId = 0;
  const id = tryId++;

  const tryToConnectAux = () => {
    if (timeoutId < 0) {
      return;
    }

    console.log(Date.now(), `[${id}]     : fetching...`);
    fetch('http://192.168.5.1/cmd', {
      method: 'POST',
      mode: 'no-cors',
      body: { cmd: 12 }, // Try a real command
    }).then(res => {
      if (timeoutId < 0) {
        return;
      }

      cb(res.ok);
      if (!res.ok) {
        timeoutId = setTimeout(tryToConnectAux, 5000);
      }
    }, err => {
      if (timeoutId < 0) {
        return;
      }

      console.warn(err);
      cb(false);
      timeoutId = setTimeout(tryToConnectAux, 5000);
    });
  };

  tryToConnectAux();

  return () => {
    console.log(Date.now(), `[${id}]     : cancelling...`);
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = -1;
  };
}

function noop() {
  // do nothing
}

export default {
  setup() {
    const { connInfo, maybeIms } = getConnectionState();

    const cameraAccessible = ref(false);
    let cancelConnect = noop;
    watchEffect(() => {
      console.log('WATCH', maybeIms.value, connInfo.value);
      if (!maybeIms.value) {
        cancelConnect();
        cancelConnect = noop;
        console.log(Date.now(), '     : skipping...');
        return;
      }

      cancelConnect();
      cancelConnect = tryToConnect(connected => {
        cameraAccessible.value = connected;
        if (connected) {
          setTimeout(() => {
            window.location.href = 'http://192.168.5.1/';
          }, 2000);
        }
      });
    });

    return {
      connInfo,
      maybeIms,
      cameraAccessible,
    };
  },
};
</script>

<style>
* {
  box-sizing: border-box;
}

html, body, #root {
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

body {
  /* XXX: IMS-6 has it set to #f5f5f5. We must match the background of
   * disconnected.png. Ideally, the image should be transparent.
   */
  background-color: #fafafa;
  color: #222222;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 1.4;
  margin: 0;
  display: flex;
  justify-content: center;
}

#app {
  max-width: 550px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

#app .status {
  max-width: 300px;
}
</style>
